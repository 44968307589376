import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import GoogleRating from "./google-rating";

const Hero = ({ title, logo, intro, media, badge, pageTitle, reviews }) => {
  const image = media?.videoPosterchildImageSharp || media;
  const mediaClass = media ? " hero-with-media" : "";
  const headerClass = media ? "hero-title" : "heading";

  return (
    <header className={`hero${mediaClass}`}>
      <div className="container">
        <div>
          {pageTitle === false ? (
            <span className={headerClass}>
              {logo ? (
                <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} />
              ) : (
                title
              )}
            </span>
          ) : (
            <h1 className={headerClass}>
              {logo ? (
                <GatsbyImage image={logo.gatsbyImageData} alt={logo.title} />
              ) : (
                title
              )}
            </h1>
          )}
          {intro && (
            <p
              className="introduction"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          )}
          {reviews && (
            <GoogleRating variant={badge ? "hero-with-badge" : "hero"} />
          )}
        </div>
      </div>
      {media && (
        <figure>
          {image.gatsbyImageData && (
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.title}
              loading="eager"
              fetchpriority="high"
              aria-hidden={media.video ? true : undefined}
            />
          )}
          {media.video && (
            <LazyLoad>
              <video
                width={1920}
                height={1080}
                autoPlay
                muted
                loop
                playsInline
                aria-hidden
              >
                <source src={media.video.publicURL} type="video/mp4" />
              </video>
            </LazyLoad>
          )}
        </figure>
      )}
      {badge && (
        <img
          src={badge.file.url}
          alt={badge.title}
          width={badge.file.details.image.width}
          height={badge.file.details.image.height}
          decoding="async"
        />
      )}
    </header>
  );
};

export default Hero;
